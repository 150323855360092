import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/UpdateLayout.js";
import List from '../../../components/LinkCard/List';
import ListItem from '../../../components/LinkCard/ListItem';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 {...{
      "id": "second-to-last-building-blocks",
      "style": {
        "position": "relative"
      }
    }}>{`Second-to-last building blocks!`}</h2>
    <p>{`With three new building blocks in this release, we're really nearing the last
batch of low-level components. Next release we'll be tackling Tooltip and
WideList components, but after that there will be a temporary component-stop in
order to prepare for our 1.0 release!`}</p>
    <p>{`Next to two straightforward components, the Avatar and the Table, we also added
a very generic Text component. This is meant to more easily resonate your
implementation with how a designer designed their visuals. That way you don't
necessarily need to default to either Caption, Paragraph or Heading for random
instances of text.`}<List mt={8} items={[{
        title: 'Avatar',
        to: `/components/avatar/code`
      }, {
        title: 'Table',
        to: `/components/table/code`
      }, {
        title: 'Text',
        to: `/components/text/code`
      }]} mdxType="List" /></p>
    <List my={8} mdxType="List">
  <ListItem title="v0.9.0 changelog" description="Get a full overview of what's changed in the changelog" to="/updates/changelog" mdxType="ListItem" />
    </List>
    <h2 {...{
      "id": "looking-forward",
      "style": {
        "position": "relative"
      }
    }}>{`Looking forward`}</h2>
    <p>{`Every release, we'll try to give you an insight on our plans for the next
release. That way you know what to expect, and see how you can adapt to our new
features.`}</p>
    <p>{`We are very close to finishing the base set of building blocks. As mentioned in
the first paragraph, next release will see our final two components before the
1.0 release.`}</p>
    <p>{`This will include some breaking changes (e.g. deprecated tokens). As with all
breaking changes, we'll always try to indicate it as deprecated for a while,
after which it will be effectively removed in the next major release.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      